/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


(function ($) {

    var revealToggle = true;

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                function addBlacklistClass() {
                    $('a').each(function () {
                        if (this.href.indexOf('/wp-admin/') !== -1 ||
                            this.href.indexOf('/wp-login.php') !== -1) {
                            $(this).addClass('wp-link');
                        }
                    });
                }

                addBlacklistClass();

                $("*[data-sr-id]").removeAttr("data-sr-id");


                /***==============================================
                 * Element Reveals
                 ===============================================*/
                window.sr = new ScrollReveal();

                sr.reveal('.image-reveal', {
                    duration: 0,
                    viewOffset: {top: 40, right: 40, bottom: 0, left: 40},

                    beforeReveal: function (item) {
                        $(item).removeAttr("style");

                        var revealDirection;
                        revealDirection = revealToggle ? "lr" : "rl";
                        revealToggle = !revealToggle;

                        console.log(revealDirection);

                        var rev = new RevealFx(item, {
                            revealSettings: {
                                bgcolor: "#EDEDED",
                                direction: revealDirection,
                                delay: 250,
                                onCover: function (contentEl, revealerEl) {
                                    contentEl.style.opacity = 1;
                                }
                            }
                        });
                        rev.reveal();

                        dragscroll.reset();

                    }
                });

                var revealCount = 0;
                sr.reveal('.reveal', {
                    scale: 1,
                    duration: 0,
                    viewOffset: {top: 40, right: 40, bottom: 0, left: 40},

                    beforeReveal: function (item) {

                        var currentBackground = $(item).css("backgroundColor");
                        var backC;

                        var revealDirection = $(item).attr("data-rev-dir");
                        revealDirection = revealDirection || "lr";


                        console.log(currentBackground);
                        if (currentBackground !== "rgba(0, 0, 0, 0)") {
                            backC = currentBackground;
                        } else {
                            backC = "#EDEDED";
                        }

                        var rev = new RevealFx(item, {
                            revealSettings: {
                                bgcolor: backC,
                                direction: revealDirection,
                                delay: 250,
                                onCover: function (contentEl, revealerEl) {
                                    contentEl.style.opacity = 1;
                                }
                            }
                        });
                        revealCount++;

                        (function (revealCount) {
                            setTimeout(function () {
                                rev.reveal();
                            }, 50 * revealCount);
                        })(revealCount);

                    }
                });


                sr.reveal('h1, .portfolio__subheading, .portfolio__sitelink, .link-border, .contact-container p', {
                    viewFactor: 1,
                    distance: '50px',
                    scale: 0,
                    rotate: { x: 0, y: 0, z: 0 },
                    duration: 1250,
                    viewOffset: {top: 40, right: 40, bottom: 80, left: 40},

                    afterReveal: function (item) {
                        $(item).removeAttr("style");
                    }
                }, 100);


                sr.reveal('#portfolio .section h2, .section-header, #portfolio .title, #portfolio .desc, #portfolio p', {
                    viewFactor: 1,
                    distance: '50px',
                    scale: 0,
                    rotate: { x: 0, y: 0, z: 0 },
                    duration: 1250,
                    viewOffset: {top: 40, right: 40, bottom: 80, left: 40},

                    afterReveal: function (item) {
                        $(item).removeAttr("style");
                    }
                });


            },
            finalize: function () {


            }
        },
        'portfolio': {
            init: function () {
                // JavaScript to be fired on the about us page
                $('.port_slider').owlCarousel({
                    loop: true,
                    responsiveClass: true,
                    navContainer: ".portfolio__slider__nav",
                    lazyLoad: true,
                    smartSpeed: 1000,
                    dots: false,
                    margin: 0,
                    responsive: {
                        0: {
                            items: 1,
                            nav: true
                        },
                        600: {
                            items: 2
                        }
                    }
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            var pageName = $(".main").children().attr("id");

            $.each(pageName.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    function activateLoadingScreen($container) {

        // number of loaded images for preloader progress
        var loadedCount = 0; //current number of images loaded
        var loadingProgress = 0; //timeline progress - starts at 0

        var loading = imagesLoaded(document.querySelector('#main'), {background: true}, function () {
            // images have loaded
            $container.removeClass('loading');
            $("body").removeClass('loading');
            $(".loading-screen").removeClass('loading');

            loadedCount = 0; //current number of images loaded
            loadingProgress = 0; //timeline progress - starts at 0

        });

        loading.on("progress", function (instance, image) {

            //one more image has been loaded
            loadedCount++;

            loadingProgress = (loadedCount / instance.elements.length);

            $(".loading-bar").animate({"width": (loadingProgress * 100) + "%"}, 500);


        });

        loading.on("always", function (instance) {
            $(".loading-bar").css("width", "100%");
            $("body").addClass('loaded');
        });
    }

    $(document).ready(function () {




        /* =================================================
         First Page Load
         ==================================================*/

        activateLoadingScreen($(".main"));


        /* =================================================
         Page Specific JS
         ==================================================*/

        UTIL.loadEvents();

        /* =================================================
         Smooth State
         ==================================================*/
        var settings = {
            anchors: 'a',
            blacklist: '.wp-link',
            prefetch: true,
            onStart: {
                duration: 350, // ms
                render: function ($container) {
                    $container.addClass('loading');
                    $("body").addClass('loading');
                    $(".loading-screen").addClass('loading');
                    $("body").removeClass('loaded');
                    $(".loading-bar").removeAttr('style');

                    $("html").animate({scrollTop: 0}, "slow");

                }
            },
            onProgress: {
                // How long this animation takes
                duration: 280,
                // A function that dictates the animations that take place
                render: function ($container) {
                }
            },
            onAfter: function ($container) {
                UTIL.loadEvents();

                activateLoadingScreen($container);


                //Google Analytics
                window.ga("set", "page", window.location.pathname);
                window.ga('send', 'pageview');
            }
        };

        var smState = $('#main').smoothState(settings);
        var content = smState.data('smoothState');

        var additionalSelectors = [
            $('[data-sm-link]'),
            $('nav a')
        ];

        function attachSmoothState(item) {
            $(item).on('mousedown touchstart', function () {
                var href = $(this).attr('href');
                content.load(href);
            }).on('click', function (e) {
                e.preventDefault();
                var href = $(this).attr('href');
                content.load(href);
            });
        }

        $(additionalSelectors).each(function (index, item) {
            attachSmoothState(item);
        });

        /* =================================================
         Mobile Menu
         ==================================================*/


        var headerMenu = (function () {
            var mobileMenuActive = false;


            var removeMobileMenu = function () {
                $(".mobile__menu").remove();
                mobileMenuActive = false;
            };

            var toggleMobileMenu = function () {
                $(".mobile__menu").toggleClass("open");
                $(".nav-toggle").toggleClass("active");
            };

            var closeMobileMenu = function () {
                $(".mobile__menu").removeClass("open");
                $(".nav-toggle").removeClass("active");
            };

            var setMobileMenu = function () {
                if (!mobileMenuActive) {
                    mobileMenuActive = true;

                    var menuContainer = $('<div class="mobile__menu"></div>'),
                        menuList = $('<div class="mobile__nav"></div>');


                    /* Add items to menu
                     ============================================================*/
                    $(".nav-bar").clone().appendTo(menuList); //Menu Links
                    menuContainer.append(menuList);


                    //Mobile Footer
                    var mobileFooter = $("<div class='mobile__footer'></div>");
                    $("footer").contents().clone().appendTo(mobileFooter);
                    $(mobileFooter).appendTo(menuContainer);

                    $("body").append(menuContainer);

                    $("<div class='mobile__email'><div class='mobile__email__label'>Email:</div><a href='mailto:josephanson@hotmail.co.uk'>josephanson@hotmail.co.uk</a></div>").prependTo($('.mobile__footer')); //Email

                    attachSmoothState($(".mobile__nav a"));
                    $(".mobile__nav a").click(toggleMobileMenu);
                }
            };

            var checkMobileMenu = function () {
                var windowWidth = window.innerWidth;

                //Checks if it's in the mobile view
                var newMobileMenuState = windowWidth <= 1023;

                //Checks if new mobile state is different to the old
                if (newMobileMenuState !== mobileMenuActive) {
                    if (newMobileMenuState) {
                        setMobileMenu();
                    } else {
                        removeMobileMenu();
                    }
                }
            };


            var init = function () {
                var windowWidth = window.innerWidth;

                if (windowWidth <= 1023) {
                    setMobileMenu();
                }
            };

            return {
                init: init,
                checkMobileMenu: checkMobileMenu,
                toggleMobileMenu: toggleMobileMenu,
                closeMobileMenu: closeMobileMenu
            };
        })();


        //Check if mobile menu is needed on window load and resize
        $(document).ready(headerMenu.init);
        $(window).resize(headerMenu.checkMobileMenu);

        $(".nav-toggle").click(headerMenu.toggleMobileMenu);
        $(".nav .logo").click(headerMenu.closeMobileMenu);


    });


})(jQuery); // Fully reference jQuery after this point.



